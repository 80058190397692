import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';
import styled from 'styled-components';

const Toggle = styled.div`
  height: 6rem;
  padding: 0 1rem;
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    color: #333;
    cursor: pointer;
    text-indent: 3rem;
    white-space: nowrap;
    width: 2.5rem;
    height: 1.5rem;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 1rem;
    height: 1rem;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 60%;
  }
`;

export default ({ data }) => {
  const [fullWidth, setFullWidth] = useState(false);

  const PortfolioContainer = styled.div`
    max-width: 1200px;
    columns: ${fullWidth ? '1' : '2 390px'};
    column-gap: 0;
    margin: 0 auto;
    @media only screen and (max-width: 800px) {
      columns: ${fullWidth ? '1' : '2'};
    }
  `;

  const Portfolio = data.Portfolio.edges.map(({ node: portfolio }) =>
    portfolio.frontmatter.posttype === 'Project' ? (
      <Link key={portfolio.id} to={portfolio.fields.slug}>
        <Img
          style={{ breakInside: 'avoid', overflow: 'visible' }}
          title={portfolio.frontmatter.title}
          fluid={portfolio.frontmatter.coverimage.childImageSharp.fluid}
          alt={portfolio.frontmatter.title}
        />
      </Link>
    ) : null
  );
  return (
    <Layout>
      <SEO title='Portfolio' description="Josh Long's Design Portfolio" pathname='/portfolio/' />
      <h2 style={{ paddingLeft: '1rem' }}>Portfolio</h2>
      <p style={{ padding: '0 1rem' }}>
        Click on a project to see more images and to find out more
      </p>
      <Toggle>
        <input
          style={{ display: 'inline' }}
          type='checkbox'
          name='switchdisplay'
          id='switch'
          aria-label='Switch Display Style'
          onClick={() => (fullWidth === false ? setFullWidth(true) : setFullWidth(false))}
        />
        <label htmlFor='switch'>{fullWidth ? 'Show Tiled Images' : 'Show Full Width Images'}</label>
      </Toggle>
      <PortfolioContainer>{Portfolio}</PortfolioContainer>
    </Layout>
  );
};

export const query = graphql`
  query {
    Portfolio: allMarkdownRemark(
      filter: { frontmatter: { posttype: { ne: "Blog" } } }
      sort: { fields: [frontmatter___priority], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            posttype
            coverimage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
